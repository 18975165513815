.HomeContainer {
    .main-image-container {
        width: 100%;
        overflow: hidden;
        position: relative;

        .main-image {
            width: 100%;
            object-fit: cover;
            height: auto;
        }

        .register-button {
            position: absolute;
            left: 50%;
            bottom: -5px;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            border: none;
            cursor: pointer;
            border-radius: 5px;
            font-size: medium;
        }
    }

    .content {
        padding: 5rem 0;

        .panel-content {
            padding: 1rem;
        }

        .facebook-iframe{
            text-align: center;
            position: relative;
            overflow: hidden;
            padding:0px;
            
        }


        .hide-panel{
            display:none;
        }
    }

}



@include media-breakpoint-down(md) {

    .HomeContainer {
        .main-image-container {
    
            .main-image {
                height: auto;
            }

            .register-button { 
                font-size: small;
            }

        }

        .content {
            padding: 1rem 0;
    
        }
    
    }
}