$eventHeight:120px;

#EventsContainer {
    width: 100%;
    height: 100%;
    font-size: 15px;
    margin-bottom: 20px;

    .small-year {
        font-size: 20px;
        font-weight: normal;
    }
    
    .calendar {
        padding-top: 5px;
        float: left;
        width: 100px;
        height: $eventHeight;
        background: #ededef; /* background:-webkit-gradient(linear,left top,left bottom,from(#ededef),to(#ccc)); */
        background: -moz-linear-gradient(top, #ededef, #ccc);
        font-size: 14px;
        font-weight: bold;;
        text-align: center;
        color: #000;
        text-shadow: #fff 0 1px 0;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px; /* border-radius:3px; */
        position: relative;
        -moz-box-shadow: 0 2px 2px #888; /* -webkit-box-shadow:0 2px 2px #888; */
        box-shadow: 0 2px 2px #888;
        border-top: 20px solid $accent;
    }
    
    .event-image-small{
        height: $eventHeight;
    }
    
    .calendar em {
        display: block;
        font: normal bold 11px/30px Arial, Helvetica, sans-serif;
        color: #fff;
        background: $accent;
        -moz-border-radius-bottomright: 3px;
        -webkit-border-bottom-right-radius: 3px;
        border-bottom-right-radius: 3px;
        -moz-border-radius-bottomleft: 3px;
        -webkit-border-bottom-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    
    .calendar:before, .calendar:after {
        content: '';
        float: left;
        position: absolute;
        top: -14px;
        width: 10px;
        height: 10px;
        background: #111;
        z-index: 1;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        -moz-box-shadow: 0 1px 1px #fff;
        -webkit-box-shadow: 0 1px 1px #fff;
        box-shadow: 0 1px 1px #fff
    }
    
    .calendar:before {
        left: 11px
    }
    
    .calendar:after {
        right: 11px
    }
    
    .calendar em:before, .calendar em:after {
        content: '';
        float: left;
        position: absolute;
        top: -25px;
        width: 6px;
        height: 17px;
        background: #dadada;
        background: -webkit-gradient(linear, left top, left bottom, from(#f1f1f1), to(#aaa));
        background: -moz-linear-gradient(top, #f1f1f1, #aaa);
        z-index: 2;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px
    }
    
    .calendar em:before {
        left: 13px
    }
    
    .calendar em:after {
        right: 13px
    }
    
    .event-row{
        margin-bottom: 25px;
        border: 5px solid white;
        margin-left: 0px;
        margin-right: 0px;

    }
    
    // todo make into
    .event-row:hover{
      background: #EBEBEB;
    }
    
    @media only screen and (max-width: 768px) {
        /* For mobile phones: */

        .event-row{
            margin-left:-25px;
            margin-right: -25px
        }
        .calendar-section{
            width: 34%;
        }
        .events-details-section{
            width:66%;
        }

        .event-image-small{
            height: 100%;
            width: 100%
        }
        .calendar{
            height: 145px;
        }
      }

}