$accent: #1b75bf;

$white: #fff;
$black: #000;


// White Darken
$white-d10: darken( $white, 10% );
$white-d20: darken( $white, 20% );
$white-d30: darken( $white, 30% );
$white-d40: darken( $white, 40% );
$white-d50: darken( $white, 50% );
$white-d60: darken( $white, 60% );
$white-d70: darken( $white, 70% );
$white-d80: darken( $white, 80% );
$white-d90: darken( $white, 90% );

// White Alpha
$white-a10: rgba( $white, 0.1 );
$white-a20: rgba( $white, 0.2 );
$white-a30: rgba( $white, 0.3 );
$white-a40: rgba( $white, 0.4 );
$white-a50: rgba( $white, 0.5 );
$white-a60: rgba( $white, 0.6 );
$white-a70: rgba( $white, 0.7 );
$white-a80: rgba( $white, 0.8 );
$white-a90: rgba( $white, 0.9 );


// Black Lighten
$black-l10: lighten( $black, 10% );
$black-l20: lighten( $black, 20% );
$black-l30: lighten( $black, 30% );
$black-l40: lighten( $black, 40% );
$black-l50: lighten( $black, 50% );
$black-l60: lighten( $black, 60% );
$black-l70: lighten( $black, 70% );
$black-l80: lighten( $black, 80% );
$black-l90: lighten( $black, 90% );

// Black Alpha
$black-a10: rgba( $black, 0.1 );
$black-a20: rgba( $black, 0.2 );
$black-a30: rgba( $black, 0.3 );
$black-a40: rgba( $black, 0.4 );
$black-a50: rgba( $black, 0.5 );
$black-a60: rgba( $black, 0.6 );
$black-a70: rgba( $black, 0.7 );
$black-a80: rgba( $black, 0.8 );
$black-a90: rgba( $black, 0.9 );