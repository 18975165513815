
$title-background: #359C8D;
$title-font-size: 18px;
$title-padding: 15px;

$prayer-names-background: #86C4BA;
$prayer-names-padding: 10px;
$prayer-names-font-size: 16px;

$header-cell-font-size: 14px;
$header-cell-padding: 10px;

$cell-font-size: 18px;

.monthlyTimetable {
    // width: 85%;
    max-width: 1200px;
    cursor: pointer;
    border-collapse: collapse;    
    margin: 30px auto;    
    border-radius: 10px;

    &__cell, &__headerCell {        
        padding: 6px; 
        border: 1px solid #ccc;         
    }

    &__row--highlight {
        background-color: #86C4BA;
        color: white;
    }

    &__cell {
        min-width: 20px;
        font-size: $cell-font-size;
        text-align: center;
    }

    &__headerCell {                        
        font-weight: bold; 
        text-align: center;
        font-size: $header-cell-font-size;
        padding: $header-cell-padding;
    }

    &__headerGroupPrayers {
        background-color: $prayer-names-background;        
    }
    
    &__prayerHeadCell {
        width: 20%;

        &--date {
            min-width: 95px;
            width: auto;
        }

        &--day {        
            width: auto;
        }

        &--magarib {
            width: 15%;
        }
    }


    &__prayerName {
        display: inline-block;
        border: 2px solid #ffff;
        border-radius: 20px;
        width: 90%;
    }


    &__headerTtitleContainer {
        background-color: $title-background;
    }


    &__title {
        display: inline-block;
        border: 2px solid #ffff;
        border-radius: 20px;
        width: 50%;
        font-size: $title-font-size;
    }


    &__prayerHeadCell, &__prayerTitleCell {
        border: none;
        padding: $prayer-names-padding;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1.5px;
        font-weight: bold;
        color: white;
        font-size: $prayer-names-font-size;
    }

    &__prayerTitleCell {
        padding: $title-padding;  
    }

}

@media
only screen and (min-width: 760px) {
    
    .monthlyTimetable__cells:hover {
        background-color: beige;
        color:#000;
    }
    
    .monthlyTimetable {
        &__row--highlight {
            background-color: none;
        }
    }
}

/* 
Mobile view
*/
@media only screen and (max-width: 768px) {


    .monthlyTimetable__cell {
        min-width: 20px;
        font-size: 18px;
        text-align: left;
        padding-left: 37%;
    }

	/* Force table to not be like tables anymore */
    .monthlyTimetable, 
    .monthlyTimetable thead, 
    .monthlyTimetable tbody, 
    .monthlyTimetable th, 
    .monthlyTimetable td, 
    .monthlyTimetable tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
    thead tr.monthlyTimetable__headerGroupPrayers,
    thead tr.monthlyTimetable__headerTitles { 
		position: absolute;
		top: -9999px;
		left: -9999px;
    }

    .monthlyTimetable__cells {
        margin-bottom: 20px;
        border: 1px solid #ccc;
    }
	
	.monthlyTimetable__cell { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #ccc;
		position: relative;
		padding-left: 42%; 
	}
	
	.monthlyTimetable__cell:before { 
		position: absolute;
        white-space: nowrap;
        width: 30%;
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 6px;
        border-right: 1px solid #ccc;
        font-weight: bold;
    }
    
    .monthlyTimetable__cell:after { 
    white-space: nowrap;
    width: 40%;
    position: absolute;
    top: 0px;
    left: 60%;
    font-weight: bold;
    z-index: 100;
    padding: 27px 20px;
    background-color: #fff;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.5px;
    font-size: 16px;
    border-left: 1px solid #ccc;
    height: 79px;
    background-color: #86C4BA;
    color: white;
    }

    .monthlyTimetable__cell:nth-of-type(3):after { 
        padding: 47px 0px;
        content: "Fajr";
        height: 119px;
    }

    .monthlyTimetable__cell:nth-of-type(6):after { 
        content: "Zuhr";         
    }

    .monthlyTimetable__cell:nth-of-type(8):after { 
        content: "Asr";         
    }

    .monthlyTimetable__cell:nth-of-type(10):after { 
        padding: 7px;
        height: 39px;
        content: "Maghrib";         
    }

    .monthlyTimetable__cell:nth-of-type(11):after { 
        content: "Isha";         
    }
	
	/*
	Label the data
	*/
	.monthlyTimetable__cell:nth-of-type(1):before { content: "Date"; }
	.monthlyTimetable__cell:nth-of-type(2):before { content: "Day"; }
	.monthlyTimetable__cell:nth-of-type(3):before { content: "Begins"; }
	.monthlyTimetable__cell:nth-of-type(4):before { content: "Iqamah"; }
	.monthlyTimetable__cell:nth-of-type(5):before { content: "Sunrise"; }
	.monthlyTimetable__cell:nth-of-type(6):before { content: "Begins"; }
	.monthlyTimetable__cell:nth-of-type(7):before { content: "Iqamah"; }
	.monthlyTimetable__cell:nth-of-type(8):before { content: "Begins"; }
	.monthlyTimetable__cell:nth-of-type(9):before { content: "Iqamah"; }
	.monthlyTimetable__cell:nth-of-type(10):before { content: "Begins"; }
	.monthlyTimetable__cell:nth-of-type(11):before { content: "Iqamah"; }
	.monthlyTimetable__cell:nth-of-type(11):before { content: "Begins"; }
	.monthlyTimetable__cell:nth-of-type(12):before { content: "Iqamah"; }
}