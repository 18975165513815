.Strivefor5Container {
    p.blue-text {
    position: absolute;
    background-color: $accent;
    color: white;
    font-size: 28px;
    text-align: center;
    margin: 0;
    bottom: 0;
    opacity: .8;
    left: 15px;
    right: 15px;
}
}



.Strivefor5Container {
    p.toptext {
    position: absolute;
    background-color: $accent;
    color: white;
    font-size: 28px;
    text-align: center;
    margin: 0;
    top: 0;
    opacity: .8;
    left: 15px;
    right: 15px;
}
}