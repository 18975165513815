@import './theme/theme';

/** Third party libraries */
@import '~@fortawesome/fontawesome-free/css/all.css';

html {
  font-size: 10px;

}

body {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
}

.btn-primary {
  @include button-variant($accent, darken($accent, 7.5%), darken($accent, 10%), lighten($accent,5%), lighten($accent, 10%), darken($accent,30%));
}

.btn-outline-primary {
  @include button-outline-variant($accent, #222222, lighten($accent,5%), $accent);
}