.Footer-component {
    .navbar {
        padding: 20px;
        font-size: 1.2rem;
        background-color: $accent;

        .copyright-notice{
            margin: 0 auto;
        }
    }
}