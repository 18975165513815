$font-size-full-screen: 2.5vw;
$font-size-small-screens: 5.4vw;
 #TimetableScreenContainer {

  background-color: $accent;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
  padding: 0px 0px 0px 0px;  

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .text-labels {
      font-size: $font-size-small-screens;
    }

    .text-labels-left {
      font-size: $font-size-small-screens;
    }

    .clockContainer {
      text-align: center;
      color: whitesmoke;
      font-size: 8vw;
    }

    .contact-info {
      display: none;
    }
  }

  @media only screen and (min-width: 1000px) {    

    c.logo-text {
      padding-top: 30px;
    }

    .logo-text img {
      height: 210px
    }

    .clockContainer {
      text-align: center;
      color: whitesmoke;
      font-size: 100px;
    }

    .hijra-date {
      font-size: 2.8vw;
    }

    .text-labels {
      font-size: $font-size-full-screen;
    }

    .text-labels-left {
      font-size: $font-size-full-screen;
    }
  }

  .timetable-screen th,
  .timetable-screen td {
    color: whitesmoke;
  }

  .logo-text {
    color: whitesmoke;
  }

  .date-text {
    color: whitesmoke;
  }

  .time-text {
    color: whitesmoke;
  }

  .text-labels {
    background-color: #1b75bf;
    text-align: center;
    color: whitesmoke;
  }

  .start {
    text-align: center;
  }

  .jamat {
    text-align: center;
  }

  .slideshow-images {
    width: 100%;
    display: block;
    position: absolute;
  }

  .fade-enter {
    opacity: 0.01;
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
  }
  
  .fade-leave {
    opacity: 1;
  }
  
  .fade-leave.fade-leave-active {
    opacity: 0.3;
    transition: opacity 300ms ease-in;
  }

  .text-labels-left {
    color: whitesmoke;
    background-color: #092037
  }

  .left-timetable {
    padding: 20px 0px 20px 0px;
  }

  .table-container {
    margin: 0 auto;
  }

  .fa-sync-alt {
    color: white;
  }

  .refresh-icon-container {
    text-align: center;
  }

  .right-slideshow {
    padding-right: 0px;
    padding-left: 0px;
  }

  .hijra-date {
    text-align: center;
    color: white;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .contact-info {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }

  .contact-all {
    height: 68px;
    margin-top: 50px;
  }

  .cross-fade-leave {
    opacity: 1;
  }
  .cross-fade-leave.cross-fade-leave-active {
    opacity: 0;
    transition: opacity 1s ease-in;
  }
  
  .cross-fade-enter {
    opacity: 0;
  }
  .cross-fade-enter.cross-fade-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in;
  }
  
  .cross-fade-height {
    transition: height 0.5s ease-in-out;
  }

 }

