#EventInfoContainer{
    margin-bottom: 20px;
    .event-title{
        text-align: center;
    }
    
    .event-info-text{
        font-size: 14px;
    }
    .event-top-section{
    margin-left: 20px;
    }
    
    .event-image-large{
        max-width: 800px;
        width: 80vw;
    }
    
    .calendar-margin{
        margin-right: 20px;
    }

}


