#DownloadsContainer{

    .downloads-table-headings{
        background-color: $accent;
        color: white;
        font-size: 16px
    }

    .row-highlight:hover{        
        background-color: $accent;
        color:white;
    }

    .row-highlight:hover a{        
        color:white;
    }

    .file-names{
        font-size:16px
    }

    .file-names-heading{
        font-size:16px
    }

}