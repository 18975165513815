.HeaderContainer {
  .navbar {
    // padding: 2.5rem 2.5rem;
    // padding-top: 0;
    background: $white;
    height: 326px;

    .button-links {
      display: flex;
      flex-direction: column;
      margin-top: -130px;
    }

    .button-links-desktop {
      display: flex;
      flex-direction: column;
      width: 115px;
    }

    .donate-button-mobile {
      display: none;
    }

    .navbar-brand {
      width: 180px;
      font-size: 3rem;
      margin-left: 25px;

      .brand-logo-container {
        margin-top: -124px;
        background: $accent;
        padding: 25px 5px 5px 5px;
        width: 180px;

        img {
          width: 100%;
        }
      }
    }

    #main-nav {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 17px;

      .navbar-nav {
        font-size: 1.5rem;

        .nav-link {
          @include transition(0.5s);
          color: $black-l20;

          &:hover,
          &:focus {
            color: $accent;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .HeaderContainer {
    .navbar {
      height: 260px;

      .donate-button-mobile {
        color: white;
        // font-size: medium;
        // position: relative;
        display: block;
        // bottom: 25%;
        // right: 29px;
        // font-size: 9px;
        margin-bottom: 10px;
      }

      .navbar-brand {
        margin-left: 20px;
        .brand-logo-container {
          // Debugging
          // background: blue;

          margin-top: -105px;
          width: 100px;
          height: 156px;
        }
      }

      #main-nav {
        font-size: 1.6rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        -webkit-transform: translateX(0);
        transform: translateX(0);

        &.show {
          position: fixed;
          z-index: 100033;
          top: 260px;
          bottom: 0;
          right: 0;
          left: 0;
          background: $white-a90;
        }

        .navbar-nav {
          padding-top: 10px;
          font-size: 1.6rem;

          .nav-item {
            padding: 1rem 0;
          }
        }
      }
    }

    #menu-toggle {
      width: 20px;
      height: 20px;
      z-index: 10000;

      position: absolute;
      bottom: -31px;
      right: 10px;
      @include transform(rotate(0deg));
      @include transition(0.5s);
      cursor: pointer;

      & > p {
        margin: 0;
        position: absolute;
        right: 25px;
        top: -5px;
        font-size: 1.3rem;
      }

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $black-l40;
        border-radius: 5px;
        opacity: 1;
        left: 0;
        @include transform(rotate(0deg));
        @include transition(0.2s);

        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 5px;
        }

        &:nth-child(4) {
          top: 10px;
        }
      }

      &.open {
        #remove-me {
          top: 0;
          width: 0%;
          left: 50%;
        }
        span {
          &:nth-child(1),
          &:nth-child(4) {
            top: 0;
            width: 0%;
            left: 50%;
          }

          &:nth-child(2) {
            @include transform(rotate(45deg));
          }

          &:nth-child(3) {
            @include transform(rotate(-45deg));
          }
        }
      }
    }
  }
}

// Small devices like Moto G4 360x640
@include media-breakpoint-down(sm) {
  .HeaderContainer {
    .navbar {
      .navbar-brand {
        .brand-logo-container {
          // Debugging
          // background: red;
          margin-top: -109px;
        }
      }
    }
  }
}
