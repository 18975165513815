#Timetable {
    z-index: 1030;
    position: fixed;
    top: 50px;
    right: 100px;

    .donate-button-desktop {
        margin-right: 5px;
        margin-top: 5px;
        position: relative;
        right: 170px;
        top: 84px;
        font-size: small;
    }


    .desktop-hidden {
        display: none;
    }

    .salah-times-table {
        table-layout: fixed;
        width: 500px;
        font-size:1.4rem;
        th,
        .table-heading {
            color: $accent;
            text-align: center;
        }

        td{
           text-align: center; 
        }
    }
}


@include media-breakpoint-down(md) {
    #Timetable {
        z-index: 1030;
        position: fixed;
        top: 120px;
        left: 0;
        right: 0;
        padding: 0.5rem 0.5rem;
        background: $white;
        
       
        .current-date {
            float: right;
        }

        .mobile-hidden {
            display: none;
        }


        .mobile-screen{
            font-size: 23px;
            display: block;
        }

        .salah-times-table {
            // background-color: lightblue;
           
            table-layout: fixed;
            min-width: 320px;
            max-width: 500px;
            font-size: 12px;
            text-align: center;

            th,
            .table-heading {
                color: $accent;
            }

            td,
            th {
                text-align: center;
            }
        }

    }
}


@include media-breakpoint-down(sm) {
    #Timetable {

        .donate-button-desktop{
            display: none;
        }

        .salah-times-table {
            // background-color: lightgreen;
            width: 100vw;
            font-size: 3vw;
            text-align: center;
        }

    }
}